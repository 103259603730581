<template>
  <div>
    <MyHeader />
    <main id="content">
      <ProfileMenu />
      <div class="container mt-4 mt-lg-5">
        <div class="row justify-content-between">
          <section class="col-12 col-lg-8">
            <h1 class="title mb-0">
              Send a message to your Concierge
            </h1>
            <p>Have a question about your trip? Any Request to dinners, groceries or more? You can message any time</p>
            <br>
            <form action=""
                  id="sendMessage"
                  @submit.prevent="messageForm">
              <textarea placeholder="Write your message…"
                        v-model="message"
                        v-validate="'required'"
                        name="message"
                        class="form-control" />
              <button class="btn btn-primary">
                SEND
              </button>
            </form>
            <span class="text-danger small mb-3 d-block fix-margin pt-3"
                  v-if="errors.has('message')">{{ errors.first('message') }}</span>
            <br>
            <div class="alert alert-success"
                 role="alert"
                 v-if="responseMessage">
              {{ responseMessage }}
            </div>
            <ul class="message-history">
              <div v-for="(_message, index) in messages"
                   :key="index">
                <li :class="{ mymsg: _message.user_id === $store.getters.getUser.id }">
                  <div class="msg"
                       v-html="_message.message" />
                  <div class="user">
                    <div class="thumb">
                      <img :src="_message.user && _message.user.avatar ? _message.user.avatar.path : '/img/user-thumb.jpg'">
                    </div>
                    <div class="name">
                      {{ _message.user.name }} {{ _message.user.surname }}
                    </div>
                    <div class="date">
                      {{ _message.created_at|beatyDate }}
                    </div>
                  </div>
                </li>
              </div>
            </ul>
          </section>
          <aside class="col-12 col-md-6 mx-md-auto mx-lg-0 col-lg-4 col-xl-3"
                 v-if="booking && booking.concierge && booking.property.state && booking.property.country">
            <div class="book-message-info">
              <div class="concierge">
                <div class="user">
                  <div class="pic">
                    <img :src="booking && booking.concierge.avatar ? message.booking.concierge.avatar.path : '/img/user-thumb.jpg'">
                  </div>
                  <div class="name">
                    <h4>{{ booking.concierge.name }} {{ booking.concierge.surname }}</h4>
                    <p>Your Concierge</p>
                  </div>
                </div>
                <hr>
                <a :href="`tel:+${booking.concierge.phone}`"
                   class="btn btn-sm btn-block"><i class="icon-phone" /> {{ booking.concierge.phone }}</a>
                <p><small>You can call me or send me a whatsapp message</small></p>
              </div>
              <div class="info">
                <h2>{{ booking.property.name }}</h2>
                <h4 class="location">
                  {{ booking.property.state.name }}, {{ booking.property.country.name }}
                </h4>
                <label class="calenBook">
                  <div><small>Check In</small> {{ booking.check_in|beatyDate }}</div>
                  <i class="icon-arrow-right" />
                  <div><small>Check In</small> {{ booking.check_out|beatyDate }}</div>
                </label>
              </div>
            </div>
          </aside>
        </div>
      </div>
      <ContactOurExperts />
    </main>
    <MyFooter />
  </div>
</template>

<script lang="ts">
import ProfileMenu from '../../components/users/ProfileMenu.vue';
import ContactOurExperts from '../../components/users/ContactOurExperts.vue';

export default {
  name: 'UserMessageDetail',
  components: {
    ProfileMenu,
    ContactOurExperts,
  },
  data() {
    return {
      messages: {},
      message: '',
      responseMessage: null,
      booking: {},
    };
  },
  created() {
    this.getMessages();
    const formData = new FormData();
    formData.append('_method', 'put');
    this.$axios.post(`/v1/bookings/${this.$route.params.id}/messages`, formData).then(() => {
    });
    this.$axios.get(`/v1/bookings/${this.$route.params.id}`).then((bookingResponse) => {
      this.booking = bookingResponse.data.data;
    });
  },
  methods: {
    messageForm() {
      this.$validator.validate().then((result) => {
        if (result) {
          const formData = new FormData();
          formData.append('message', this.message);
          this.$axios.post(`/v1/bookings/${this.$route.params.id}/messages`, formData).then((response) => {
            this.responseMessage = response.data.message;
            this.message = '';
            this.$validator.reset();
            this.getMessages();
          });
        }
      });
    },
    getMessages() {
      this.$axios.get(`/v1/bookings/${this.$route.params.id}/messages`).then((res) => {
        this.messages = res.data.data;
      });
    }
  },
};
</script>

<style lang="scss" scoped>

</style>
